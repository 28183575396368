import { createRoot } from "react-dom/client";
import React, { useState } from "react";
import { uploadFile } from "../assets/js/init";
import axios from "axios";

const el = document.getElementById("root");

if (root) {
	const query = location.search.slice(1);
	const params = query.split("&");

	let amount;

	for (const param of params) {
		const [_, value] = param.split("=");
		amount = value;
	}

	const App = () => {
		// const { useState } = React;

		const [state, sA] = useState({
			register: {
				firstName: "",
				lastName: "",
				email: "",
				telephoneNo: "",
				dateOfBirth: "",
			},
			businessInformation: {
				name: "",
				officeAddress: "",
				addressOfFactory: "",
				belongsTo: "",
				panCardNo: "",
				constitution: "",
				dateOfEstablishment: "",
				country: "",
				state: "",
				city: "",
				branchWhereLoanIsRequired: "",
			},
			proprietors: [
				{
					name: "",
					dateOfBirth: "",
					relationship: "",
					academicQualification: "",
					mobileNo: "",
					panNo: "",
					residentialAddress: "",
					residentialTelephoneNumber: "",
					experienceInLineOfActivity: "",
					activity: {
						existing: "",
						since: "",
						proposed: "",
					},
				},
			],
			associate: [
				{
					name: "",
					address: "",
					extentOfInterest: "",
					natureOfAssociation: "",
					relationshipOfProprietors: "",
					relationshipConcerns: "",
				},
			],
			bankingCreditFacilities: {
				type: "",
				limit: "",
				outstanding: "",
				bankNameBranch: "",
				securities: "",
				repaymentTerms: "",
				status: "",
			},
			creditFacilities: {
				type: "",
				amount: "",
				purposeRequiredForWhich: "",
				primarySecurity: "",
				collateral: "",
				sales: "",
				workingCycleInMonths: "",
				inventory: "",
				debtors: "",
				creditors: "",
				otherCurrentAssets: "",
				promotersContribution: "",
			},
			machinery: {
				type: "",
				purposeRequiredForWhich: "",
				whetherImportedOrIndigenous: "",
				nameOfSupplier: "",
				totalCost: "",
				contribution: "",
				loanRequired: "",
			},
			thirdPartyCollateral: [
				{
					name: "",
					address: "",
					telephoneNo: "",
					mobileNo: "",
					netWorth: "",
					panNo: "",
				},
			],
			otherCollateral: [
				{
					name: "",
					nature: "",
					details: "",
					value: "",
				},
			],
			identificationProof: {
				id: undefined,
				address: undefined,
			},
			error: [],
			submit: false,
			eMsg: "",
		});

		const setState = (ns) => {
			sA((cs) => ({ ...cs, ...ns }));
		};

		const submit = () => {
			state.error = [];
			state.eMsg = "";

			if (
				!state.register.firstName ||
				state.register.firstName.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "register.firstName",
					msg: "first name is required",
				});

			if (
				!state.register.lastName ||
				state.register.lastName.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "register.lastName",
					msg: "last name is required",
				});

			if (
				!state.register.email ||
				state.register.email.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "register.email",
					msg: "email is required",
				});

			if (
				!state.register.telephoneNo ||
				state.register.telephoneNo.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "register.telephoneNo",
					msg: "telephone number is required",
				});

			if (
				!state.register.dateOfBirth ||
				state.register.dateOfBirth.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "register.dateOfBirth",
					msg: "date of birth is required",
				});

			if (
				!state.businessInformation.name ||
				state.businessInformation.name.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.name",
					msg: "date of birth is required",
				});

			if (
				!state.businessInformation.officeAddress ||
				state.businessInformation.officeAddress.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.officeAddress",
					msg: "office address is required",
				});

			if (
				!state.businessInformation.addressOfFactory ||
				state.businessInformation.addressOfFactory.replace(/\s/g, "").length ==
					0
			)
				state.error.push({
					field: "businessInformation.addressOfFactory",
					msg: "address o factory is required",
				});

			if (
				!state.businessInformation.belongsTo ||
				state.businessInformation.belongsTo.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.belongsTo",
					msg: "this is required",
				});

			if (
				!state.businessInformation.panCardNo ||
				state.businessInformation.panCardNo.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.panCardNo",
					msg: "panCard number is required",
				});

			if (
				!state.businessInformation.constitution ||
				state.businessInformation.constitution.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.constitution",
					msg: "constitution is required",
				});

			if (
				!state.businessInformation.dateOfEstablishment ||
				state.businessInformation.dateOfEstablishment.replace(/\s/g, "")
					.length == 0
			)
				state.error.push({
					field: "businessInformation.dateOfEstablishment",
					msg: "date of establishment is required",
				});

			if (
				!state.businessInformation.country ||
				state.businessInformation.country.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.country",
					msg: "country is required",
				});

			if (
				!state.businessInformation.state ||
				state.businessInformation.state.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.state",
					msg: "state is required",
				});

			if (
				!state.businessInformation.city ||
				state.businessInformation.city.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "businessInformation.city",
					msg: "city is required",
				});

			if (
				!state.businessInformation.branchWhereLoanIsRequired ||
				state.businessInformation.branchWhereLoanIsRequired.replace(/\s/g, "")
					.length == 0
			)
				state.error.push({
					field: "businessInformation.branchWhereLoanIsRequired",
					msg: "branch where loan is required",
				});

			for (let i = 0; i < state.proprietors.length; i++) {
				const p = state.proprietors[i];

				if (!p.name || p.name.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `proprietors${i}.name`,
						msg: "name is required",
					});

				if (!p.dateOfBirth || p.dateOfBirth.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `proprietors${i}.dateOfBirth`,
						msg: "date of birth is required",
					});

				if (!p.relationship || p.relationship.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `proprietors${i}.relationship`,
						msg: "relationship is required",
					});

				if (
					!p.academicQualification ||
					p.academicQualification.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `proprietors${i}.academicQualification`,
						msg: "academic qualification is required",
					});

				if (!p.mobileNo || p.mobileNo.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `proprietors${i}.mobileNo`,
						msg: "mobile number is required",
					});

				if (!p.panNo || p.panNo.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `proprietors${i}.panNo`,
						msg: "panNo is required",
					});

				if (
					!p.residentialAddress ||
					p.residentialAddress.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `proprietors${i}.residentialAddress`,
						msg: "residential address is required",
					});

				if (
					!p.residentialTelephoneNumber ||
					p.residentialTelephoneNumber.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `proprietors${i}.residentialTelephoneNumber`,
						msg: "residential telephone number is required",
					});

				if (
					!p.experienceInLineOfActivity ||
					p.experienceInLineOfActivity.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `proprietors${i}.experienceInLineOfActivity`,
						msg: "experience in line of activity is required",
					});

				if (
					!p.activity ||
					!p.activity.existing ||
					p.activity.existing.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `proprietors${i}.activity.existing`,
						msg: "this field is required",
					});

				if (
					!p.activity ||
					!p.activity.since ||
					p.activity.since.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `proprietors${i}.activity.since`,
						msg: "this field is required",
					});

				if (
					!p.activity ||
					!p.activity.proposed ||
					p.activity.proposed.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `proprietors${i}.activity.proposed`,
						msg: "this field is required",
					});
			}

			for (let i = 0; i < state.associate.length; i++) {
				const p = state.associate[i];

				if (!p.name || p.name.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `associate${i}.name`,
						msg: "name is required",
					});

				if (!p.address || p.address.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `associate${i}.address`,
						msg: "address is required",
					});

				if (
					!p.extentOfInterest ||
					p.extentOfInterest.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `associate${i}.extentOfInterest`,
						msg: "extent of interest is required",
					});

				if (
					!p.natureOfAssociation ||
					p.natureOfAssociation.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `associate${i}.natureOfAssociation`,
						msg: "nature of association is required",
					});

				if (
					!p.relationshipOfProprietors ||
					p.relationshipOfProprietors.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `associate${i}.relationshipOfProprietors`,
						msg: "relationship of proprietors is required",
					});

				if (
					!p.relationshipConcerns ||
					p.relationshipConcerns.replace(/\s/g, "").length == 0
				)
					state.error.push({
						field: `associate${i}.relationshipConcerns`,
						msg: "relationship cConcerns is required",
					});
			}

			if (
				!state.bankingCreditFacilities.type ||
				state.bankingCreditFacilities.type.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "bankingCreditFacilities.type",
					msg: "type is required",
				});

			if (
				!state.bankingCreditFacilities.limit ||
				state.bankingCreditFacilities.limit.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "bankingCreditFacilities.limit",
					msg: "limit is required",
				});

			if (
				!state.bankingCreditFacilities.outstanding ||
				state.bankingCreditFacilities.outstanding.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "bankingCreditFacilities.outstanding",
					msg: "outstanding is required",
				});

			if (
				!state.bankingCreditFacilities.bankNameBranch ||
				state.bankingCreditFacilities.bankNameBranch.replace(/\s/g, "")
					.length == 0
			)
				state.error.push({
					field: "bankingCreditFacilities.bankNameBranch",
					msg: "bank name branch is required",
				});

			if (
				!state.bankingCreditFacilities.securities ||
				state.bankingCreditFacilities.securities.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "bankingCreditFacilities.securities",
					msg: "securities is required",
				});

			if (
				!state.bankingCreditFacilities.repaymentTerms ||
				state.bankingCreditFacilities.repaymentTerms.replace(/\s/g, "")
					.length == 0
			)
				state.error.push({
					field: "bankingCreditFacilities.repaymentTerms",
					msg: "repayment terms is required",
				});

			if (
				!state.bankingCreditFacilities.status ||
				state.bankingCreditFacilities.status.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "bankingCreditFacilities.status",
					msg: "status is required",
				});

			if (
				!state.creditFacilities.type ||
				state.creditFacilities.type.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "creditFacilities.type",
					msg: "type is required",
				});

			if (
				!state.creditFacilities.amount ||
				state.creditFacilities.amount.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "creditFacilities.amount",
					msg: "amount is required",
				});

			if (
				!state.creditFacilities.purposeRequiredForWhich ||
				state.creditFacilities.purposeRequiredForWhich.replace(/\s/g, "")
					.length == 0
			)
				state.error.push({
					field: "creditFacilities.purposeRequiredForWhich",
					msg: "this field is required",
				});

			if (
				!state.creditFacilities.primarySecurity ||
				state.creditFacilities.primarySecurity.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "creditFacilities.primarySecurity",
					msg: "primary security is required",
				});

			if (
				!state.creditFacilities.collateral ||
				state.creditFacilities.collateral.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "creditFacilities.collateral",
					msg: "collateral is required",
				});

			if (
				state.creditFacilities.type.toLowerCase().includes("credit") &&
				(!state.creditFacilities.sales ||
					state.creditFacilities.sales.replace(/\s/g, "").length == 0)
			)
				state.error.push({
					field: "creditFacilities.sales",
					msg: "sales is required",
				});

			if (
				state.creditFacilities.type.toLowerCase().includes("credit") &&
				(!state.creditFacilities.workingCycleInMonths ||
					state.creditFacilities.workingCycleInMonths.replace(/\s/g, "")
						.length == 0)
			)
				state.error.push({
					field: "creditFacilities.workingCycleInMonths",
					msg: "working cycle in months is required",
				});

			if (
				state.creditFacilities.type.toLowerCase().includes("credit") &&
				(!state.creditFacilities.inventory ||
					state.creditFacilities.inventory.replace(/\s/g, "").length == 0)
			)
				state.error.push({
					field: "creditFacilities.inventory",
					msg: "inventory is required",
				});

			if (
				state.creditFacilities.type.toLowerCase().includes("credit") &&
				(!state.creditFacilities.debtors ||
					state.creditFacilities.debtors.replace(/\s/g, "").length == 0)
			)
				state.error.push({
					field: "creditFacilities.debtors",
					msg: "debtors is required",
				});

			if (
				state.creditFacilities.type.toLowerCase().includes("credit") &&
				(!state.creditFacilities.creditors ||
					state.creditFacilities.creditors.replace(/\s/g, "").length == 0)
			)
				state.error.push({
					field: "creditFacilities.creditors",
					msg: "creditors is required",
				});

			if (
				state.creditFacilities.type.toLowerCase().includes("credit") &&
				(!state.creditFacilities.otherCurrentAssets ||
					state.creditFacilities.otherCurrentAssets.replace(/\s/g, "").length ==
						0)
			)
				state.error.push({
					field: "creditFacilities.otherCurrentAssets",
					msg: "other current assets is required",
				});

			if (
				state.creditFacilities.type.toLowerCase().includes("credit") &&
				(!state.creditFacilities.promotersContribution ||
					state.creditFacilities.promotersContribution.replace(/\s/g, "")
						.length == 0)
			)
				state.error.push({
					field: "creditFacilities.promotersContribution",
					msg: "promoters contribution is required",
				});

			if (
				!state.machinery.type ||
				state.machinery.type.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "machinery.type",
					msg: "type is required",
				});

			if (
				!state.machinery.purposeRequiredForWhich ||
				state.machinery.purposeRequiredForWhich.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "machinery.purposeRequiredForWhich",
					msg: "this is required",
				});

			if (
				!state.machinery.whetherImportedOrIndigenous ||
				state.machinery.whetherImportedOrIndigenous.replace(/\s/g, "").length ==
					0
			)
				state.error.push({
					field: "machinery.whetherImportedOrIndigenous",
					msg: "this is required",
				});

			if (
				!state.machinery.nameOfSupplier ||
				state.machinery.nameOfSupplier.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "machinery.nameOfSupplier",
					msg: "name of supplier is required",
				});

			if (
				!state.machinery.totalCost ||
				state.machinery.totalCost.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "machinery.totalCost",
					msg: "total cost is required",
				});

			if (
				!state.machinery.type ||
				state.machinery.contribution.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "machinery.contribution",
					msg: "contribution is required",
				});

			if (
				!state.machinery.loanRequired ||
				state.machinery.loanRequired.replace(/\s/g, "").length == 0
			)
				state.error.push({
					field: "machinery.loanRequired",
					msg: "this is required",
				});

			for (let i = 0; i < state.thirdPartyCollateral.length; i++) {
				const t = state.thirdPartyCollateral[i];

				if (!t.name || t.name.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `thirdPartyCollateral${i}.name`,
						msg: "name is required",
					});

				if (!t.address || t.address.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `thirdPartyCollateral${i}.address`,
						msg: "address is required",
					});

				if (!t.telephoneNo || t.telephoneNo.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `thirdPartyCollateral${i}.telephoneNo`,
						msg: "telephone number is required",
					});

				if (!t.mobileNo || t.mobileNo.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `thirdPartyCollateral${i}.mobileNo`,
						msg: "mobile number is required",
					});

				if (!t.netWorth || t.netWorth.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `thirdPartyCollateral${i}.netWorth`,
						msg: "net worth is required",
					});

				if (!t.panNo || t.panNo.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `thirdPartyCollateral${i}.panNo`,
						msg: "panNo is required",
					});
			}

			for (let i = 0; i < state.otherCollateral.length; i++) {
				const t = state.otherCollateral[i];

				if (!t.name || t.name.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `otherCollateral${i}.name`,
						msg: "name is required",
					});

				if (!t.nature || t.nature.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `otherCollateral${i}.nature`,
						msg: "nature is required",
					});

				if (!t.details || t.details.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `otherCollateral${i}.details`,
						msg: "details is required",
					});

				if (!t.value || t.value.replace(/\s/g, "").length == 0)
					state.error.push({
						field: `otherCollateral${i}.value`,
						msg: "value is required",
					});
			}

			if (!state.identificationProof.id)
				state.error.push({
					field: "identificationProof.id",
					msg: "your id is required",
				});

			if (!state.identificationProof.address)
				state.error.push({
					field: "identificationProof.address",
					msg: "a paid utility bill with your address is required",
				});

			setState(state);

			if (state.error.length == 0 && state.submit === false) {
				state.submit = true;

				setState(state);

				uploadFile(state.identificationProof.id, ({ type, msg }) => {
					if (type == "error") {
						state.eMsg = msg;
						setState(state);
					} else {
						const idUrl = msg;

						uploadFile(state.identificationProof.address, ({ type, msg }) => {
							if (type == "error") {
								state.eMsg = msg;
								setState(state);
							} else {
								const addressUrl = msg;

								const data = { ...JSON.parse(JSON.stringify(state)), amount };

								data.identificationProof.id = idUrl;
								data.identificationProof.address = addressUrl;

								delete data.error;
								delete data.error;

								axios
									.post("https://opec-ae-org.onrender.com/request-loan", data, {
										timeout: 1440000,
									})
									.then((res) => {
										console.log("Success:", res);

										alert("success");
										window.location.replace("/");
									})
									.catch((error) => {
										console.error("Error:", error);

										state.submit = false;

										state.eMsg =
											"Unable to complete your application, Please try again.";

										setState(state);
									});
							}
						});
					}
				});
			}
		};

		return (
			<div>
				<section className="details-header site-padding f-size">
					<div className="max-view p-relative" style={{ height: "100%" }}>
						<div className="summary">
							<button className="btn btn-blue btn-compress f-size">
								<span className="btn-text">loan</span>
							</button>

							<h1 className="h1-container f-size">
								<span className="h1-text">Request Loan</span>
							</h1>
						</div>
					</div>
				</section>

				<section className="max-view site-padding f-size">
					<button className="btn btn-blue btn-compress f-size">
						<span className="btn-text">Request Loan</span>
					</button>

					<form>
						<div className="space"></div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">1st </span>
								<span className="btn-text">Register</span>
							</div>

							<div className="form-group">
								<div className="form-control">
									<label className="h2-text" htmlFor="telephoneNo">
										First Name
									</label>
									<input
										className={`${
											state.error.find((e) => e.field == "register.firstName")
												? "form-error"
												: ""
										}`}
										type="text"
										name="firstName"
										value={state.register.firstName}
										onChange={(e) => {
											state.register.firstName = e.target.value;

											state.error.filter(
												(e) => e.field != "register.firstName"
											);

											setState(state);
										}}
									/>

									{state.error.find((e) => e.field == "register.firstName") ? (
										<div className="form-error-container">
											{
												state.error.find((e) => e.field == "register.firstName")
													.msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="lastName">
										Last Name (Surname)
									</label>
									<input
										className={`${
											state.error.find((e) => e.field == "register.lastName")
												? "form-error"
												: ""
										}`}
										type="text"
										name="lastName"
										value={state.register.lastName}
										onChange={(e) => {
											state.register.lastName = e.target.value;

											state.error.filter((e) => e.field != "register.lastName");

											setState(state);
										}}
									/>

									{state.error.find((e) => e.field == "register.lastName") ? (
										<div className="form-error-container">
											{
												state.error.find((e) => e.field == "register.lastName")
													.msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="email">
										email
									</label>
									<input
										className={`${
											state.error.find((e) => e.field == "register.email")
												? "form-error"
												: ""
										}`}
										type="email"
										name="email"
										value={state.register.email}
										onChange={(e) => {
											state.register.email = e.target.value;

											state.error.filter((e) => e.field != "register.email");

											setState(state);
										}}
									/>

									{state.error.find((e) => e.field == "register.email") ? (
										<div className="form-error-container">
											{state.error.find((e) => e.field == "register.email").msg}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="telephoneNo">
										Telephone No
									</label>
									<input
										className={`${
											state.error.find((e) => e.field == "register.telephoneNo")
												? "form-error"
												: ""
										}`}
										type="tel"
										name="telephoneNo"
										value={state.register.telephoneNo}
										onChange={(e) => {
											state.register.telephoneNo = e.target.value;

											state.error.filter(
												(e) => e.field != "register.telephoneNo"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "register.telephoneNo"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "register.telephoneNo"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="dateOfBirth">
										Date Of Birth
									</label>
									<input
										className={`${
											state.error.find((e) => e.field == "register.dateOfBirth")
												? "form-error"
												: ""
										}`}
										type="date"
										name="dateOfBirth"
										value={state.register.dateOfBirth}
										onChange={(e) => {
											state.register.dateOfBirth = e.target.value;

											console.log(e.target.value);

											state.error.filter(
												(e) => e.field != "register.dateOfBirth"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "register.dateOfBirth"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "register.dateOfBirth"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">2nd </span>
								<span className="btn-text">Business Information</span>
							</div>

							<div className="form-group">
								<div className="form-control">
									<label className="h2-text" htmlFor="nameOfEnterprise">
										Name of Enterprise
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.name"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="nameOfEnterprise"
										value={state.businessInformation.name}
										onChange={(e) => {
											state.businessInformation.name = e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.name"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.name"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.name"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="registeredOfficeAddress">
										Registered Office Address
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.officeAddress"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="registeredOfficeAddress"
										value={state.businessInformation.officeAddress}
										onChange={(e) => {
											state.businessInformation.officeAddress = e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.officeAddress"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.officeAddress"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.officeAddress"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="addressOfFactory">
										Address of Factory/Shop
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.addressOfFactory"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="addressOfFactory"
										value={state.businessInformation.addressOfFactory}
										onChange={(e) => {
											state.businessInformation.addressOfFactory =
												e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.addressOfFactory"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.addressOfFactory"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field == "businessInformation.addressOfFactory"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="belongs-to-sc-st-obc">
										Whether Belongs to SC/ST/OBC/Minority Community If Yes, then
										Please Select: SC/ ST/ OBC/ Minority Community
									</label>

									<div className="radio-group">
										<label className="h2-text" htmlFor="belongs-to-sc-st-obc">
											YES
										</label>
										<input
											className={`${
												state.error.find(
													(e) => e.field == "businessInformation.belongsTo"
												)
													? "form-error"
													: ""
											}`}
											type="radio"
											name="belongs-to-sc-st-obc"
											value="yes"
											onChange={(e) => {
												state.businessInformation.belongsTo = e.target.value;

												state.error.filter(
													(e) => e.field != "businessInformation.belongsTo"
												);

												setState(state);
											}}
										/>

										<label className="h2-text" htmlFor="belongs-to-sc-st-obc">
											NO
										</label>
										<input
											className={`${
												state.error.find(
													(e) => e.field == "businessInformation.belongsTo"
												)
													? "form-error"
													: ""
											}`}
											type="radio"
											name="belongs-to-sc-st-obc"
											value="no"
											onChange={(e) => {
												state.businessInformation.belongsTo = e.target.value;

												state.error.filter(
													(e) => e.field != "businessInformation.belongsTo"
												);

												setState(state);
											}}
										/>
									</div>

									{state.error.find(
										(e) => e.field == "businessInformation.belongsTo"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.belongsTo"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="panCardNo">
										PAN Card No
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.panCardNo"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="panCardNo"
										value={state.businessInformation.panCardNo}
										onChange={(e) => {
											state.businessInformation.panCardNo = e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.panCardNo"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.panCardNo"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.panCardNo"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="constitution">
										Constitution (Please Select)
									</label>

									<select
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.constitution"
											)
												? "form-error"
												: ""
										}`}
										name="constitution"
										value={state.businessInformation.constitution}
										onChange={(e) => {
											state.businessInformation.constitution = e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.constitution"
											);

											setState(state);
										}}>
										<option defaultValue="">choose...</option>
										<option value="Individual">Individual</option>
										<option value="Joint">Joint</option>
										<option value="Prop. Concern">Prop. Concern</option>
										<option value="Partnership">Partnership</option>
										<option value="Pvt Ltd Co">Pvt Ltd Co</option>
										<option value="Limited Co">Limited Co</option>
										<option value="Trust">Trust</option>
										<option value="other">other</option>
									</select>

									{state.error.find(
										(e) => e.field == "businessInformation.constitution"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.constitution"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="dateOfEstablishment">
										Date Of Establishment
									</label>
									<input
										className={`${
											state.error.find(
												(e) =>
													e.field == "businessInformation.dateOfEstablishment"
											)
												? "form-error"
												: ""
										}`}
										type="date"
										name="dateOfEstablishment"
										value={state.businessInformation.dateOfEstablishment}
										onChange={(e) => {
											state.businessInformation.dateOfEstablishment =
												e.target.value;

											state.error.filter(
												(e) =>
													e.field != "businessInformation.dateOfEstablishment"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.dateOfEstablishment"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field == "businessInformation.dateOfEstablishment"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="country">
										Country
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.country"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="country"
										value={state.businessInformation.country}
										onChange={(e) => {
											state.businessInformation.country = e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.country"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.country"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.country"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="state">
										State
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.state"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="state"
										value={state.businessInformation.state}
										onChange={(e) => {
											state.businessInformation.state = e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.state"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.state"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.state"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="city">
										City
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "businessInformation.city"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="city"
										value={state.businessInformation.city}
										onChange={(e) => {
											state.businessInformation.city = e.target.value;

											state.error.filter(
												(e) => e.field != "businessInformation.city"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "businessInformation.city"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "businessInformation.city"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label
										className="h2-text"
										htmlFor="branchWhereLoanIsRequired">
										Branch where loan is required
									</label>
									<input
										className={`${
											state.error.find(
												(e) =>
													e.field ==
													"businessInformation.branchWhereLoanIsRequired"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="branchWhereLoanIsRequired"
										value={state.businessInformation.branchWhereLoanIsRequired}
										onChange={(e) => {
											state.businessInformation.branchWhereLoanIsRequired =
												e.target.value;

											state.error.filter(
												(e) =>
													e.field !=
													"businessInformation.branchWhereLoanIsRequired"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) =>
											e.field == "businessInformation.branchWhereLoanIsRequired"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field ==
														"businessInformation.branchWhereLoanIsRequired"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">3rd </span>
								<span className="btn-text">
									Name of Proprietors/ Partners/ Directors of Company and Their
									Addresses
								</span>
							</div>

							{state.proprietors.map((p, i) => (
								<div key={i}>
									<h2 className="h2-text">#{i + 1}</h2>

									<div className="form-group">
										<div className="form-control">
											<label className="h2-text" htmlFor="p-p-d-Name">
												Name
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `proprietors${i}.name`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="p-p-d-Name"
												value={p.name}
												onChange={(e) => {
													state.proprietors[i].name = e.target.value;

													state.error.filter(
														(e) => e.field != `proprietors${i}.name`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `proprietors${i}.name`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `proprietors${i}.name`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label className="h2-text" htmlFor="p-p-d-dateOfBirth">
												Date of Birth
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `proprietors${i}.dateOfBirth`
													)
														? "form-error"
														: ""
												}`}
												type="date"
												name="p-p-d-dateOfBirth"
												value={p.dateOfBirth}
												onChange={(e) => {
													state.proprietors[i].dateOfBirth = e.target.value;

													state.error.filter(
														(e) => e.field != `proprietors${i}.dateOfBirth`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `proprietors${i}.dateOfBirth`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `proprietors${i}.dateOfBirth`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label className="h2-text" htmlFor="p-p-d-relationship">
												Relationship
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `proprietors${i}.relationship`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="p-p-d-relationship"
												value={p.relationship}
												onChange={(e) => {
													state.proprietors[i].relationship = e.target.value;

													state.error.filter(
														(e) => e.field != `proprietors${i}.relationship`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `proprietors${i}.relationship`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `proprietors${i}.relationship`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="p-p-d-AcademicQualification">
												Academic Qualification
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field == `proprietors${i}.academicQualification`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="p-p-d-AcademicQualification"
												value={p.academicQualification}
												onChange={(e) => {
													state.proprietors[i].academicQualification =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field != `proprietors${i}.academicQualification`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) =>
													e.field == `proprietors${i}.academicQualification`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field ==
																`proprietors${i}.academicQualification`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label className="h2-text" htmlFor="p-p-d-MobileNo">
												Mobile No
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `proprietors${i}.mobileNo`
													)
														? "form-error"
														: ""
												}`}
												type="tel"
												name="p-p-d-MobileNo"
												value={p.mobileNo}
												onChange={(e) => {
													state.proprietors[i].mobileNo = e.target.value;

													state.error.filter(
														(e) => e.field != `proprietors${i}.mobileNo`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `proprietors${i}.mobileNo`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `proprietors${i}.mobileNo`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label className="h2-text" htmlFor="p-p-d-PanNo">
												PAN No
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `proprietors${i}.panNo`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="p-p-d-PanNo"
												value={p.panNo}
												onChange={(e) => {
													state.proprietors[i].panNo = e.target.value;

													state.error.filter(
														(e) => e.field != `proprietors${i}.panNo`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `proprietors${i}.panNo`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `proprietors${i}.panNo`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="p-p-d-ResidentialAddress">
												Residential Address
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field == `proprietors${i}.residentialAddress`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="p-p-d-ResidentialAddress"
												value={p.residentialAddress}
												onChange={(e) => {
													state.proprietors[i].residentialAddress =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field != `proprietors${i}.residentialAddress`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `proprietors${i}.residentialAddress`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field == `proprietors${i}.residentialAddress`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="p-p-d-ResidentialTelephoneNumber">
												Residential Telephone Number
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field ==
															`proprietors${i}.residentialTelephoneNumber`
													)
														? "form-error"
														: ""
												}`}
												type="tel"
												name="p-p-d-ResidentialTelephoneNumber"
												value={p.residentialTelephoneNumber}
												onChange={(e) => {
													state.proprietors[i].residentialTelephoneNumber =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field !=
															`proprietors${i}.residentialTelephoneNumber`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) =>
													e.field ==
													`proprietors${i}.residentialTelephoneNumber`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field ==
																`proprietors${i}.residentialTelephoneNumber`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="p-p-d-ExperienceInLineOfActivity">
												Experience In Line Of Activity
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field ==
															`proprietors${i}.experienceInLineOfActivity`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="p-p-d-ExperienceInLineOfActivity"
												value={p.experienceInLineOfActivity}
												onChange={(e) => {
													state.proprietors[i].experienceInLineOfActivity =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field !=
															`proprietors${i}.experienceInLineOfActivity`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) =>
													e.field ==
													`proprietors${i}.experienceInLineOfActivity`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field ==
																`proprietors${i}.experienceInLineOfActivity`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control flex-100">
											<label className="h2-text" htmlFor="p-p-d-Activity">
												Activity
											</label>
											<div className="form-statement">
												<span className="h2-text">Existing</span>
												<input
													className={`${
														state.error.find(
															(e) =>
																e.field == `proprietors${i}.activity.existing`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="p-p-d-ActivityExisting"
													value={p.activity.existing}
													onChange={(e) => {
														state.proprietors[i].activity.existing =
															e.target.value;

														state.error.filter(
															(e) =>
																e.field != `proprietors${i}.activity.existing`
														);

														setState(state);
													}}
												/>
												<span className="h2-text" style={{ marginLeft: "1em" }}>
													since
												</span>
												<input
													className={`${
														state.error.find(
															(e) => e.field == `proprietors${i}.activity.since`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="p-p-d-ActivitySince"
													value={p.activity.since}
													onChange={(e) => {
														state.proprietors[i].activity.since =
															e.target.value;

														state.error.filter(
															(e) => e.field != `proprietors${i}.activity.since`
														);

														setState(state);
													}}
												/>

												<div className="space"></div>

												<div className="proposed">
													<span className="h2-text">Proposed#</span>
													<input
														className={`${
															state.error.find(
																(e) =>
																	e.field == `proprietors${i}.activity.proposed`
															)
																? "form-error"
																: ""
														}`}
														type="text"
														name="p-p-d-ActivityProposed"
														value={p.activity.proposed}
														onChange={(e) => {
															state.proprietors[i].activity.proposed =
																e.target.value;

															state.error.filter(
																(e) =>
																	e.field != `proprietors${i}.activity.proposed`
															);

															setState(state);
														}}
													/>
												</div>

												<span className="h2-text">
													#if a different activity other than existing activity
													is proposed.
												</span>
											</div>

											{state.error.find(
												(e) => e.field == "proprietors.activity."
											) ? (
												<div className="form-error-container">
													{
														state.error.find((e) =>
															e.field.includes("proprietors.activity.")
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>
									</div>

									{state.proprietors.length > 1 ? (
										<div className="form-group">
											<button
												className="btn btn-red f-size"
												type="button"
												onClick={() => {
													state.proprietors = state.proprietors.filter(
														(_, I) => I != i
													);

													setState(state);
												}}>
												<span className="btn-text">
													<i className="fa-solid fa-trash"></i>
												</span>
											</button>
										</div>
									) : (
										<></>
									)}
								</div>
							))}

							<button
								className="btn btn-blue f-size"
								type="button"
								onClick={() => {
									state.proprietors.push({
										name: "",
										dateOfBirth: "",
										relationship: "",
										academicQualification: "",
										mobileNo: "",
										panNo: "",
										residentialAddress: "",
										residentialTelephoneNumber: "",
										experienceInLineOfActivity: "",
										activity: {
											existing: "",
											since: "",
											proposed: "",
										},
									});

									setState(state);
								}}>
								<span className="btn-text">
									<i className="fa-solid fa-plus"></i>
								</span>
							</button>

							<div className="space"></div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">4th </span>
								<span className="btn-text">
									Names of Associate Concerns and Nature of Association
								</span>
							</div>

							{state.associate.map((a, i) => (
								<div key={i}>
									<h2 className="h2-text">#{i + 1}</h2>

									<div className="form-group">
										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="concerns-NameOfAssociateConcerns">
												Name Of Associate Concerns
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `associate${i}.name`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="concerns-NameOfAssociateConcerns"
												value={a.name}
												onChange={(e) => {
													state.associate[i].name = e.target.value;

													state.error.filter(
														(e) => e.field != `associate${i}.name`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `associate${i}.name`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `associate${i}.name`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="concerns-AddressOfAssociate">
												Address Of Associate
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `associate${i}.address`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="concerns-AddressOfAssociate"
												value={a.address}
												onChange={(e) => {
													state.associate[i].address = e.target.value;

													state.error.filter(
														(e) => e.field != `associate${i}.address`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `associate${i}.address`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `associate${i}.address`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="concerns-PresentlyBankingWidth">
												Presently Banking Width
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == `associate${i}.extentOfInterest`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="concerns-PresentlyBankingWidth"
												value={a.extentOfInterest}
												onChange={(e) => {
													state.associate[i].extentOfInterest = e.target.value;

													state.error.filter(
														(e) => e.field != `associate${i}.extentOfInterest`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `associate${i}.extentOfInterest`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == `associate${i}.extentOfInterest`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="concerns-NatureOfAssociation">
												Nature of Association
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field == `associate${i}.natureOfAssociation`
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="concerns-NatureOfAssociation"
												value={a.natureOfAssociation}
												onChange={(e) => {
													state.associate[i].natureOfAssociation =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field != `associate${i}.natureOfAssociation`
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == `associate${i}.natureOfAssociation`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field == `associate${i}.natureOfAssociation`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="concerns-Extent-Of-Interest">
												Extent of Interest
											</label>
											<select
												className={`${
													state.error.find(
														(e) =>
															e.field ==
															`associate${i}.relationshipOfProprietors`
													)
														? "form-error"
														: ""
												}`}
												name="concerns-Extent-Of-Interest"
												value={a.relationshipOfProprietors}
												onChange={(e) => {
													state.associate[i].relationshipOfProprietors =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field !=
															`associate${i}.relationshipOfProprietors`
													);

													setState(state);
												}}>
												<option defaultValue="">choose...</option>
												<option value="Prop">Prop</option>
												<option value="Partner">Partner</option>
												<option value="Director">Director</option>
												<option value="Just Investor in Associate Concern">
													Just Investor in Associate Concern
												</option>
											</select>

											{state.error.find(
												(e) =>
													e.field == `associate${i}.relationshipOfProprietors`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field ==
																`associate${i}.relationshipOfProprietors`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor={`concerns-relationship${i}`}>
												Relationship of Proprietors/ Partners/ Directors with
												the officials of the Bank/ Director of the Bank: Please
												select
											</label>

											<div className="radio-group">
												<label
													className="h2-text"
													htmlFor={`concerns-relationship${i}`}>
													YES
												</label>
												<input
													className={`${
														state.error.find(
															(e) =>
																e.field == `associate${i}.relationshipConcerns`
														)
															? "form-error"
															: ""
													}`}
													type="radio"
													name={`concerns-relationship${i}`}
													value="yes"
													onChange={(e) => {
														state.associate[i].relationshipConcerns =
															e.target.value;

														console.log(e.target.value);

														state.error.filter(
															(e) =>
																e.field != `associate${i}.relationshipConcerns`
														);

														setState(state);
													}}
												/>

												<label
													className="h2-text"
													htmlFor={`concerns-relationship${i}`}>
													NO
												</label>
												<input
													className={`${
														state.error.find(
															(e) =>
																e.field == `associate${i}.relationshipConcerns`
														)
															? "form-error"
															: ""
													}`}
													type="radio"
													name={`concerns-relationship${i}`}
													value="no"
													onChange={(e) => {
														state.associate[i].relationshipConcerns =
															e.target.value;

														state.error.filter(
															(e) =>
																e.field != `associate${i}.relationshipConcerns`
														);

														setState(state);
													}}
												/>
											</div>

											{state.error.find(
												(e) => e.field == `associate${i}.relationshipConcerns`
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field == `associate${i}.relationshipConcerns`
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>
									</div>

									{state.associate.length > 1 ? (
										<div className="form-group">
											<button
												className="btn btn-red f-size"
												type="button"
												onClick={() => {
													state.associate = state.associate.filter(
														(_, I) => I != i
													);

													setState(state);
												}}>
												<span className="btn-text">
													<i className="fa-solid fa-trash"></i>
												</span>
											</button>
										</div>
									) : (
										<></>
									)}
								</div>
							))}

							<button
								className="btn btn-blue f-size"
								type="button"
								onClick={(e) => {
									state.associate.push({
										name: "",
										address: "",
										extentOfInterest: "",
										natureOfAssociation: "",
										relationshipOfProprietors: "",
										relationshipConcerns: "",
									});

									setState(state);
								}}>
								<span className="btn-text">
									<i className="fa-solid fa-plus"></i>
								</span>
							</button>

							<div className="space"></div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">5th </span>
								<span className="btn-text">
									Banking/Credit Facilities (Existing)
								</span>
							</div>

							<div className="form-group">
								<div className="form-control">
									<label className="h2-text" htmlFor="bank-Type">
										Type of Facilities
									</label>
									<select
										className={`${
											state.error.find(
												(e) => e.field == "bankingCreditFacilities.type"
											)
												? "form-error"
												: ""
										}`}
										name="bank-type"
										value={state.bankingCreditFacilities.type}
										onChange={(e) => {
											state.bankingCreditFacilities.type = e.target.value;

											state.error.filter(
												(e) => e.field != "bankingCreditFacilities.type"
											);

											setState(state);
										}}>
										<option defaultValue="">choose...</option>
										<option value="Current Account">Current Account</option>
										<option value="Cash Credit">Cash Credit</option>
										<option value="Term Loan">Term Loan</option>
										<option value="LC/BG">LC/BG</option>
									</select>

									{state.error.find(
										(e) => e.field == "bankingCreditFacilities.type"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "bankingCreditFacilities.type"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="bank-Limit">
										Limit
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "bankingCreditFacilities.limit"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="bank-Limit"
										value={state.bankingCreditFacilities.limit}
										onChange={(e) => {
											state.bankingCreditFacilities.limit = e.target.value;

											state.error.filter(
												(e) => e.field != "bankingCreditFacilities.limit"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "bankingCreditFacilities.limit"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "bankingCreditFacilities.limit"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="bank-Outstanding">
										Outstanding as on
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "bankingCreditFacilities.outstanding"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="bank-Outstanding"
										value={state.bankingCreditFacilities.outstanding}
										onChange={(e) => {
											state.bankingCreditFacilities.outstanding =
												e.target.value;

											state.error.filter(
												(e) => e.field != "bankingCreditFacilities.outstanding"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "bankingCreditFacilities.outstanding"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field == "bankingCreditFacilities.outstanding"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="bank-bankNameBranch">
										Name of the Bank and branch
									</label>
									<input
										className={`${
											state.error.find(
												(e) =>
													e.field == "bankingCreditFacilities.bankNameBranch"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="bank-bankNameBranch"
										value={state.bankingCreditFacilities.bankNameBranch}
										onChange={(e) => {
											state.bankingCreditFacilities.bankNameBranch =
												e.target.value;

											state.error.filter(
												(e) =>
													e.field != "bankingCreditFacilities.bankNameBranch"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "bankingCreditFacilities.bankNameBranch"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field == "bankingCreditFacilities.bankNameBranch"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="bank-Securities">
										Securities
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "bankingCreditFacilities.securities"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="bank-Securities"
										value={state.bankingCreditFacilities.securities}
										onChange={(e) => {
											state.bankingCreditFacilities.securities = e.target.value;

											state.error.filter(
												(e) => e.field != "bankingCreditFacilities.securities"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "bankingCreditFacilities.securities"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "bankingCreditFacilities.securities"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="bank-RepaymentTerms">
										Repayment Terms
									</label>
									<input
										className={`${
											state.error.find(
												(e) =>
													e.field == "bankingCreditFacilities.repaymentTerms"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="bank-RepaymentTerms"
										value={state.bankingCreditFacilities.repaymentTerms}
										onChange={(e) => {
											state.bankingCreditFacilities.repaymentTerms =
												e.target.value;

											state.error.filter(
												(e) =>
													e.field != "bankingCreditFacilities.repaymentTerms"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "bankingCreditFacilities.repaymentTerms"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field == "bankingCreditFacilities.repaymentTerms"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="bank-certified">
										It is certified that our unit has not availed any loan from
										any other Bank / Financial Institution in the past and I am
										not indebted to any other Bank / Financial Institution other
										than those mentioned in column above.
									</label>

									<textarea
										className={`${
											state.error.find(
												(e) => e.field == "bankingCreditFacilities.status"
											)
												? "form-error"
												: ""
										}`}
										cols="30"
										rows="10"
										name="bank-certified"
										value={state.bankingCreditFacilities.status}
										onChange={(e) => {
											state.bankingCreditFacilities.status = e.target.value;

											state.error.filter(
												(e) => e.field != "bankingCreditFacilities.status"
											);

											setState(state);
										}}></textarea>

									{state.error.find(
										(e) => e.field == "bankingCreditFacilities.status"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "bankingCreditFacilities.status"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">6th </span>
								<span className="btn-text"> Credit Facilities (Proposed) </span>
							</div>

							<div className="form-group">
								<div className="form-control">
									<label className="h2-text" htmlFor="credit-facilities-Type">
										Type of Facilities
									</label>
									<select
										className={`${
											state.error.find(
												(e) => e.field == "creditFacilities.type"
											)
												? "form-error"
												: ""
										}`}
										name="credit-facilities-type"
										value={state.creditFacilities.type}
										onChange={(e) => {
											state.creditFacilities.type = e.target.value;

											state.error.filter(
												(e) => e.field != "creditFacilities.type"
											);

											setState(state);
										}}>
										<option defaultValue="">choose...</option>
										<option value="Current Account">Current Account</option>
										<option value="Cash Credit">Cash Credit</option>
										<option value="Term Loan">Term Loan</option>
										<option value="LC/BG">LC/BG</option>
									</select>

									{state.error.find(
										(e) => e.field == "creditFacilities.type"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "creditFacilities.type"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="credit-facilities-Amount">
										Amount
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "creditFacilities.amount"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="credit-facilities-Amount"
										value={state.creditFacilities.amount}
										onChange={(e) => {
											state.creditFacilities.amount = e.target.value;

											state.error.filter(
												(e) => e.field != "creditFacilities.amount"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "creditFacilities.amount"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "creditFacilities.amount"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label
										className="h2-text"
										htmlFor="credit-facilities-PurposeRequiredForWhich">
										Purpose Required For Which
									</label>
									<input
										className={`${
											state.error.find(
												(e) =>
													e.field == "creditFacilities.purposeRequiredForWhich"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="credit-facilities-PurposeRequiredForWhich"
										value={state.creditFacilities.purposeRequiredForWhich}
										onChange={(e) => {
											state.creditFacilities.purposeRequiredForWhich =
												e.target.value;

											state.error.filter(
												(e) =>
													e.field != "creditFacilities.purposeRequiredForWhich"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "creditFacilities.purposeRequiredForWhich"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field ==
														"creditFacilities.purposeRequiredForWhich"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label
										className="h2-text"
										htmlFor="credit-facilities-PrimarySecurity">
										Security Offered: Primary Security(Details with approx.
										value to be mentioned)
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "creditFacilities.primarySecurity"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="credit-facilities-PrimarySecurity"
										value={state.creditFacilities.primarySecurity}
										onChange={(e) => {
											state.creditFacilities.primarySecurity = e.target.value;

											state.error.filter(
												(e) => e.field != "creditFacilities.primarySecurity"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "creditFacilities.primarySecurity"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "creditFacilities.primarySecurity"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label
										className="h2-text"
										htmlFor="credit-facilities-Collateral">
										Security Offered: Whether Collateral Security Offered
									</label>

									<div className="radio-group">
										<label
											className="h2-text"
											htmlFor="credit-facilities-Collateral">
											YES
										</label>
										<input
											className={`${
												state.error.find(
													(e) => e.field == "creditFacilities.collateral"
												)
													? "form-error"
													: ""
											}`}
											type="radio"
											name="credit-facilities-Collateral"
											value="yes"
											onChange={(e) => {
												state.creditFacilities.collateral = e.target.value;

												state.error.filter(
													(e) => e.field != "creditFacilities.collateral"
												);

												setState(state);
											}}
										/>

										<label
											className="h2-text"
											htmlFor="credit-facilities-Collateral">
											NO
										</label>
										<input
											className={`${
												state.error.find(
													(e) => e.field == "creditFacilities.collateral"
												)
													? "form-error"
													: ""
											}`}
											type="radio"
											name="credit-facilities-Collateral"
											value="no"
											onChange={(e) => {
												state.creditFacilities.collateral = e.target.value;

												state.error.filter(
													(e) => e.field != "creditFacilities.collateral"
												);

												setState(state);
											}}
										/>
									</div>

									{state.error.find(
										(e) => e.field == "creditFacilities.collateral"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "creditFacilities.collateral"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								{state.creditFacilities.type
									.toLowerCase()
									.includes("credit") ? (
									<>
										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="credit-selected-sales">
												Sales
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == "creditFacilities.sales"
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="credit-selected-sales"
												value={state.creditFacilities.sales}
												onChange={(e) => {
													state.creditFacilities.sales = e.target.value;

													state.error.filter(
														(e) => e.field != "creditFacilities.sales"
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == "creditFacilities.sales"
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == "creditFacilities.sales"
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="credit-selected-WorkingCycleInMonths">
												Working cycle in months
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field == "creditFacilities.workingCycleInMonths"
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="credit-selected-WorkingCycleInMonths"
												value={state.creditFacilities.workingCycleInMonths}
												onChange={(e) => {
													state.creditFacilities.workingCycleInMonths =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field != "creditFacilities.workingCycleInMonths"
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) =>
													e.field == "creditFacilities.workingCycleInMonths"
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field ==
																"creditFacilities.workingCycleInMonths"
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="credit-selected-Inventory">
												Inventory
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == "creditFacilities.inventory"
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="credit-selected-Inventory"
												value={state.creditFacilities.inventory}
												onChange={(e) => {
													state.creditFacilities.inventory = e.target.value;

													state.error.filter(
														(e) => e.field != "creditFacilities.inventory"
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == "creditFacilities.inventory"
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == "creditFacilities.inventory"
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="credit-selected-Debtors">
												Debtors
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == "creditFacilities.debtors"
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="credit-selected-Debtors"
												value={state.creditFacilities.debtors}
												onChange={(e) => {
													state.creditFacilities.debtors = e.target.value;

													state.error.filter(
														(e) => e.field != "creditFacilities.debtors"
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == "creditFacilities.debtors"
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == "creditFacilities.debtors"
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="credit-selected-Creditors">
												Creditors
											</label>
											<input
												className={`${
													state.error.find(
														(e) => e.field == "creditFacilities.creditors"
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="credit-selected-Creditors"
												value={state.creditFacilities.creditors}
												onChange={(e) => {
													state.creditFacilities.creditors = e.target.value;

													state.error.filter(
														(e) => e.field != "creditFacilities.creditors"
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == "creditFacilities.creditors"
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) => e.field == "creditFacilities.creditors"
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="credit-selected-OtherCurrentAssets">
												Other Current Assets
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field == "creditFacilities.otherCurrentAssets"
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="credit-selected-OtherCurrentAssets"
												value={state.creditFacilities.otherCurrentAssets}
												onChange={(e) => {
													state.creditFacilities.otherCurrentAssets =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field != "creditFacilities.otherCurrentAssets"
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) => e.field == "credit-selected-OtherCurrentAssets"
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field == "credit-selected-OtherCurrentAssets"
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>

										<div className="form-control">
											<label
												className="h2-text"
												htmlFor="credit-selected-PromotersContribution">
												Promoters Contribution
											</label>
											<input
												className={`${
													state.error.find(
														(e) =>
															e.field ==
															"creditFacilities.promotersContribution"
													)
														? "form-error"
														: ""
												}`}
												type="text"
												name="credit-selected-PromotersContribution"
												value={state.creditFacilities.promotersContribution}
												onChange={(e) => {
													state.creditFacilities.promotersContribution =
														e.target.value;

													state.error.filter(
														(e) =>
															e.field !=
															"creditFacilities.promotersContribution"
													);

													setState(state);
												}}
											/>

											{state.error.find(
												(e) =>
													e.field == "credit-selected-PromotersContribution"
											) ? (
												<div className="form-error-container">
													{
														state.error.find(
															(e) =>
																e.field ==
																"credit-selected-PromotersContribution"
														).msg
													}
												</div>
											) : (
												<></>
											)}
										</div>
									</>
								) : (
									<></>
								)}
							</div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">7th </span>
								<span className="btn-text">
									In case of term loan requirements, the details of machinery
									may be given as under:
								</span>
							</div>

							<div className="form-group">
								<div className="form-control">
									<label className="h2-text" htmlFor="machinery-Type">
										Type of Machine/ Equipment
									</label>
									<input
										className={`${
											state.error.find((e) => e.field == "machinery.type")
												? "form-error"
												: ""
										}`}
										type="text"
										name="machinery-Type"
										value={state.machinery.type}
										onChange={(e) => {
											state.machinery.type = e.target.value;

											state.error.filter((e) => e.field != "machinery.type");

											setState(state);
										}}
									/>

									{state.error.find((e) => e.field == "machinery.type") ? (
										<div className="form-error-container">
											{state.error.find((e) => e.field == "machinery.type").msg}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label
										className="h2-text"
										htmlFor="machinery-PurposeRequiredForWhich">
										Purpose for which Required
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "machinery.purposeRequiredForWhich"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="machinery-PurposeRequiredForWhich"
										value={state.machinery.purposeRequiredForWhich}
										onChange={(e) => {
											state.machinery.purposeRequiredForWhich = e.target.value;

											state.error.filter(
												(e) => e.field != "machinery.purposeRequiredForWhich"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "machinery.purposeRequiredForWhich"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "machinery.purposeRequiredForWhich"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label
										className="h2-text"
										htmlFor="machinery-WhetherImportedOrIndigenous">
										Whether Imported or Indigenous
									</label>
									<input
										className={`${
											state.error.find(
												(e) =>
													e.field == "machinery.whetherImportedOrIndigenous"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="machinery-WhetherImportedOrIndigenous"
										value={state.machinery.whetherImportedOrIndigenous}
										onChange={(e) => {
											state.machinery.whetherImportedOrIndigenous =
												e.target.value;

											state.error.filter(
												(e) =>
													e.field != "machinery.whetherImportedOrIndigenous"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "machinery.WhetherImportedOrIndigenous"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) =>
														e.field == "machinery.WhetherImportedOrIndigenous"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="machinery-NameOfSupplier">
										Name of Supplier
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "machinery.nameOfSupplier"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="machinery-NameOfSupplier"
										value={state.machinery.nameOfSupplier}
										onChange={(e) => {
											state.machinery.nameOfSupplier = e.target.value;

											state.error.filter(
												(e) => e.field != "machinery.nameOfSupplier"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "machinery.nameOFSupplier"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "machinery.nameOFSupplier"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="machinery-TotalCost">
										Total Cost of Machine ( in case of imported machine, the
										breakup of basic costs, freight, insurance and customs duty
										may be given)
									</label>
									<input
										className={`${
											state.error.find((e) => e.field == "machinery.totalCost")
												? "form-error"
												: ""
										}`}
										type="text"
										name="machinery-TotalCost"
										value={state.machinery.totalCost}
										onChange={(e) => {
											state.machinery.totalCost = e.target.value;

											state.error.filter(
												(e) => e.field != "machinery.totalCost"
											);

											setState(state);
										}}
									/>

									{state.error.find((e) => e.field == "machinery.totalCost") ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "machinery.totalCost"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="machinery-Contribution">
										Contribution being made by the promoters
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "machinery.contribution"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="machinery-Contribution"
										value={state.machinery.contribution}
										onChange={(e) => {
											state.machinery.contribution = e.target.value;

											state.error.filter(
												(e) => e.field != "machinery.contribution"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "machinery.contribution"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "machinery.contribution"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="form-control">
									<label className="h2-text" htmlFor="machinery-LoanRequired">
										Loan Required
									</label>
									<input
										className={`${
											state.error.find(
												(e) => e.field == "machinery.loanRequired"
											)
												? "form-error"
												: ""
										}`}
										type="text"
										name="machinery-LoanRequired"
										value={state.machinery.loanRequired}
										onChange={(e) => {
											state.machinery.loanRequired = e.target.value;

											state.error.filter(
												(e) => e.field != "machinery.loanRequired"
											);

											setState(state);
										}}
									/>

									{state.error.find(
										(e) => e.field == "machinery.loanRequired"
									) ? (
										<div className="form-error-container">
											{
												state.error.find(
													(e) => e.field == "machinery.loanRequired"
												).msg
											}
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">8th </span>
								<span className="btn-text">
									Details of Collateral Securities Offered, if any, including
									third party guarantee.
								</span>
							</div>

							<div>
								<h2 className="h2-text">Third Party Guarantee</h2>

								{state.thirdPartyCollateral.map((t, i) => (
									<div key={i}>
										<h2 className="h2-text">#{i + 1}</h2>
										<div className="form-group">
											<div className="form-control">
												<label className="h2-text" htmlFor="ttg-name">
													Name of Guarantor
												</label>
												<input
													className={`${
														state.error.find(
															(e) => e.field == `thirdPartyCollateral${i}.name`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="ttg-name"
													value={t.name}
													onChange={(e) => {
														state.thirdPartyCollateral[i].name = e.target.value;
														state.error.filter(
															(e) => e.field != `thirdPartyCollateral${i}.name`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `thirdPartyCollateral${i}.name`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) =>
																	e.field == `thirdPartyCollateral${i}.name`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttg-Address">
													Residential Address
												</label>
												<input
													className={`${
														state.error.find(
															(e) =>
																e.field == `thirdPartyCollateral${i}.address`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="ttg-Address"
													value={t.address}
													onChange={(e) => {
														state.thirdPartyCollateral[i].address =
															e.target.value;
														state.error.filter(
															(e) =>
																e.field != `thirdPartyCollateral${i}.address`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `thirdPartyCollateral${i}.address`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) =>
																	e.field == `thirdPartyCollateral${i}.address`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttg-tel">
													Telephone No. (Residence)
												</label>
												<input
													className={`${
														state.error.find(
															(e) =>
																e.field ==
																`thirdPartyCollateral${i}.telephoneNo`
														)
															? "form-error"
															: ""
													}`}
													type="tel"
													name="ttg-tel"
													value={t.telephoneNo}
													onChange={(e) => {
														state.thirdPartyCollateral[i].telephoneNo =
															e.target.value;
														state.error.filter(
															(e) =>
																e.field !=
																`thirdPartyCollateral${i}.telephoneNo`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) =>
														e.field == `thirdPartyCollateral${i}.telephoneNo`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) =>
																	e.field ==
																	`thirdPartyCollateral${i}.telephoneNo`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttg-MobileNo">
													Mobile No
												</label>
												<input
													className={`${
														state.error.find(
															(e) =>
																e.field == `thirdPartyCollateral${i}.mobileNo`
														)
															? "form-error"
															: ""
													}`}
													type="tel"
													name="ttg-MobileNo"
													value={t.mobileNo}
													onChange={(e) => {
														state.thirdPartyCollateral[i].mobileNo =
															e.target.value;
														state.error.filter(
															(e) =>
																e.field != `thirdPartyCollateral${i}.mobileNo`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `thirdPartyCollateral${i}.mobileNo`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) =>
																	e.field == `thirdPartyCollateral${i}.mobileNo`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttg-NetWorth">
													Net worth
												</label>
												<input
													className={`${
														state.error.find(
															(e) =>
																e.field == `thirdPartyCollateral${i}.netWorth`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="ttg-NetWorth"
													value={t.netWorth}
													onChange={(e) => {
														state.thirdPartyCollateral[i].netWorth =
															e.target.value;
														state.error.filter(
															(e) =>
																e.field != `thirdPartyCollateral${i}.netWorth`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `thirdPartyCollateral${i}.netWorth`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) =>
																	e.field == `thirdPartyCollateral${i}.netWorth`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttg-PANNo">
													PAN No
												</label>
												<input
													className={`${
														state.error.find(
															(e) => e.field == `thirdPartyCollateral${i}.panNo`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="ttg-PANNo"
													value={t.panNo}
													onChange={(e) => {
														state.thirdPartyCollateral[i].panNo =
															e.target.value;
														state.error.filter(
															(e) => e.field != `thirdPartyCollateral${i}.panNo`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `thirdPartyCollateral${i}.panNo`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) =>
																	e.field == `thirdPartyCollateral${i}.panNo`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>
										</div>

										{state.thirdPartyCollateral.length > 1 ? (
											<div className="form-group">
												<button
													className="btn btn-red f-size"
													type="button"
													onClick={() => {
														state.thirdPartyCollateral =
															state.thirdPartyCollateral.filter(
																(_, I) => I != i
															);
														setState(state);
													}}>
													<span className="btn-text">
														<i className="fa-solid fa-trash"></i>
													</span>
												</button>
											</div>
										) : (
											<></>
										)}
									</div>
								))}

								<button
									className="btn btn-blue f-size"
									type="button"
									onClick={() => {
										state.thirdPartyCollateral.push({
											name: "",
											address: "",
											telephoneNo: "",
											mobileNo: "",
											netWorth: "",
											panNo: "",
										});
										setState(state);
									}}>
									<span className="btn-text">
										<i className="fa-solid fa-plus"></i>
									</span>
								</button>

								<div className="space"></div>
							</div>

							<div>
								<h2 className="h2-text">Other Collateral Security</h2>

								{state.otherCollateral.map((o, i) => (
									<div key={i}>
										<h2 className="h2-text">#{i + 1}</h2>

										<div className="form-group">
											<div className="form-control">
												<label className="h2-text" htmlFor="ttgo-name">
													Name of owner of Collateral
												</label>
												<input
													className={`${
														state.error.find(
															(e) => e.field == `otherCollateral${i}.name`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="ttgo-name"
													value={o.name}
													onChange={(e) => {
														state.otherCollateral[i].name = e.target.value;
														state.error.filter(
															(e) => e.field != `otherCollateral${i}.name`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `otherCollateral${i}.name`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) => e.field == `otherCollateral${i}.name`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttgo-s-Nature">
													Collateral Security Nature
												</label>
												<input
													className={`${
														state.error.find(
															(e) => e.field == `otherCollateral${i}.nature`
														)
															? "form-error"
															: ""
													}`}
													type="text"
													name="ttgo-s-Nature"
													value={o.nature}
													onChange={(e) => {
														state.otherCollateral[i].nature = e.target.value;
														state.error.filter(
															(e) => e.field != `otherCollateral${i}.nature`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `otherCollateral${i}.nature`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) => e.field == `otherCollateral${i}.nature`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttgo-s-Details">
													Collateral Security Details
												</label>
												<input
													className={`${
														state.error.find(
															(e) => e.field == `otherCollateral${i}.details`
														)
															? "form-error"
															: ""
													}`}
													type="tel"
													name="ttgo-s-Details"
													value={o.details}
													onChange={(e) => {
														state.otherCollateral[i].details = e.target.value;
														state.error.filter(
															(e) => e.field != `otherCollateral${i}.details`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `otherCollateral${i}.details`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) => e.field == `otherCollateral${i}.details`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>

											<div className="form-control">
												<label className="h2-text" htmlFor="ttgo-s-Value">
													Collateral Security Value
												</label>
												<input
													className={`${
														state.error.find(
															(e) => e.field == `otherCollateral${i}.value`
														)
															? "form-error"
															: ""
													}`}
													type="tel"
													name="ttgo-s-Value"
													value={o.value}
													onChange={(e) => {
														state.otherCollateral[i].value = e.target.value;
														state.error.filter(
															(e) => e.field != `otherCollateral${i}.value`
														);
														setState(state);
													}}
												/>

												{state.error.find(
													(e) => e.field == `otherCollateral${i}.value`
												) ? (
													<div className="form-error-container">
														{
															state.error.find(
																(e) => e.field == `otherCollateral${i}.value`
															).msg
														}
													</div>
												) : (
													<></>
												)}
											</div>
										</div>

										{state.otherCollateral.length > 1 ? (
											<div className="form-group">
												<button
													className="btn btn-red f-size"
													type="button"
													onClick={() => {
														state.otherCollateral =
															state.otherCollateral.filter((_, I) => I != i);

														setState(state);
													}}>
													<span className="btn-text">
														<i className="fa-solid fa-trash"></i>
													</span>
												</button>
											</div>
										) : (
											<></>
										)}
									</div>
								))}

								<button
									className="btn btn-blue f-size"
									type="button"
									onClick={() => {
										state.otherCollateral.push({
											name: "",
											nature: "",
											details: "",
											value: "",
										});

										setState(state);
									}}>
									<span className="btn-text">
										<i className="fa-solid fa-plus"></i>
									</span>
								</button>

								<div className="space"></div>
							</div>
						</div>

						<div className="form-section">
							<div className="btn btn-white btn-border-black btn-icon btn-sm f-size">
								<span className="btn-round btn-black">9th </span>
								<span className="btn-text"> Identification Proof </span>
							</div>

							<div className="form-control">
								<label className="h2-text" htmlFor="id-idProof">
									ID Proof (subject to the satisfaction of bank)
								</label>
								<input
									className={`${
										state.error.find((e) => e.field == "identificationProof.id")
											? "form-error"
											: ""
									}`}
									type="file"
									name="id-idProof"
									onChange={(e) => {
										state.identificationProof.id = e.target.files[0];

										state.error.filter(
											(e) => e.field != "identificationProof.id"
										);

										setState(state);
									}}
								/>

								{state.error.find(
									(e) => e.field == "identificationProof.id"
								) ? (
									<div className="form-error-container">
										{
											state.error.find(
												(e) => e.field == "identificationProof.id"
											).msg
										}
									</div>
								) : (
									<></>
								)}
							</div>

							<div className="form-control">
								<label className="h2-text" htmlFor="id-idProof">
									Address Proof (a paid utility bill with your address)
								</label>
								<input
									className={`${
										state.error.find(
											(e) => e.field == "identificationProof.address"
										)
											? "form-error"
											: ""
									}`}
									type="file"
									name="id-idProof"
									onChange={(e) => {
										state.identificationProof.address = e.target.files[0];

										state.error.filter(
											(e) => e.field != "identificationProof.address"
										);

										setState(state);
									}}
								/>

								{state.error.find(
									(e) => e.field == "identificationProof.address"
								) ? (
									<div className="form-error-container">
										{
											state.error.find(
												(e) => e.field == "identificationProof.address"
											).msg
										}
									</div>
								) : (
									<></>
								)}
							</div>

							<hr />

							{state.eMsg.length > 0 ? (
								<div className="form-error-container">{state.eMsg}</div>
							) : (
								<></>
							)}

							<button
								className="btn btn-blue btn-icon mv-r f-size"
								type="button"
								onClick={() => submit()}>
								{!state.submit ? (
									<i className="fa-solid fa-circle-check fa-xl"></i>
								) : (
									<i className="fa-solid fa-arrows-rotate fa-xl rotate"></i>
								)}
								<span className="btn-text">submit</span>
							</button>
						</div>
					</form>
				</section>
			</div>
		);
	};

	// const root = ReactDOM.createRoot(el);
	const root = createRoot(el);

	root.render(<App />);
}

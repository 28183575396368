// nav bar toggle start

const openMenu = document.getElementById("open-menu"),
	closeMenu = document.getElementById("close-menu"),
	mobileMenu = document.getElementById("mobile-menu");

openMenu.addEventListener("click", () => {
	openMenu.classList.add("d-none");

	closeMenu.classList.remove("d-none");

	mobileMenu.classList.remove("d-none");
});

closeMenu.addEventListener("click", close);

if (document.body.clientWidth <= 700)
	document
		.querySelectorAll(".click")
		.forEach((el) => el.addEventListener("click", close));

function close() {
	// mobileMenu.classList.add("menu-down-reverse", "d-none");

	// const t1 = setTimeout(() => {
	// mobileMenu.classList.remove("d-none");

	// const t2 = setTimeout(() => {
	// mobileMenu.classList.remove("menu-down-reverse");
	mobileMenu.classList.add("d-none");

	closeMenu.classList.add("d-none");

	openMenu.classList.add("fade");
	openMenu.classList.remove("d-none");

	// clearTimeout(t2);
	// }, 500);

	// clearTimeout(t1);
	// }, 100);
}

// nav bar toggle end

import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyBzqTXK7LTSgaann1OCunjDjBXDw-mH-6w",
	authDomain: "dot-projects-i.firebaseapp.com",
	projectId: "dot-projects-i",
	storageBucket: "dot-projects-i.appspot.com",
	messagingSenderId: "286875139181",
	appId: "1:286875139181:web:c8a3993abf376422add364",
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export const uploadFile = (file, callback) => {
	const storageRef = ref(storage, "files/" + file.name);

	uploadBytes(storageRef, file).then((snapshot) => {
		getDownloadURL(snapshot.ref)
			.then((downloadURL) => {
				callback({ type: "success", msg: downloadURL });
			})
			.catch((error) => {
				console.log(error);
				callback({ type: "error", msg: "Unknown error occurred" });
			});
	});
};
